<template>  
  <div class="register-container">  
    <h1>用户注册</h1>  
    <form @submit.prevent="registerUser" class="register-form">  
      <div class="form-item">  
        <label for="username">用户名：</label>  
        <input type="text" id="username" v-model="username" placeholder="请输入用户名" required />  
      </div>  
      <div class="form-item">  
        <label for="password">密&nbsp;&nbsp;码：</label>  
        <input type="password" id="password" v-model="password" placeholder="请输入密码" required />  
      </div>  
      <div class="btn-group">  
        <button type="button" @click="back" class="back-btn">返回</button>  
        <button type="submit" class="register-btn">注册</button>  
      </div>  
    </form>  
  </div>  
</template>  

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    async registerUser() {
      if (!this.username || !this.password) {
        return this.$message.error('用户名和密码不能为空');
      }
      try {
        const res = await axios.post("http://47.120.26.136:3005/register", {
          username: this.username,
          password: this.password
        });
        if (res.data.code === 0) {
          console.log(res.data);
          this.$message({
            message: "注册成功",
            type: "success"
          });
          this.$router.replace("/login");
          // 清空表单
          this.username = "";
          this.password = "";
        } else if (res.data.code === 1) {
          console.log(res.data);
           this.$message.error('用户名已存在，请重新填写');
        } else {
          alert("注册失败");
        }
      } catch (error) {
        alert("注册失败");
        console.error(error);
      }
    },
    back() {
      this.$router.replace("/login");
    }
  }
};
</script>

<style scoped>  
.register-container {  
  width: 400px;  
  margin: auto;  
  padding: 30px;  
  background-color: #ffffff;  
  border-radius: 8px;  
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}  

.register-form {  
  display: flex;  
  flex-direction: column;  
}  

h1 {  
  text-align: center;  
  margin-bottom: 20px;  
  color: #333;  
}  

.form-item {  
  margin-bottom: 15px;  
}  

.form-item label {  
  font-size: 14px;  
  color: #555;  
}  

input {  
  width: 100%;  
  padding: 10px;  
  margin-top: 5px;  
  border: 1px solid #ccc;  
  border-radius: 5px;  
  font-size: 16px;  
}  

input:focus {  
  outline: none;  
  border-color: #409EFF; /* Element UI primary color */  
  box-shadow: 0 0 5px rgba(64, 158, 255, 0.5);  
}  

.btn-group {  
  display: flex;  
  justify-content: space-between;  
}  

.back-btn,  
.register-btn {  
  width: 48%;  
  padding: 10px;  
  border: none;  
  border-radius: 5px;  
  cursor: pointer;  
  font-size: 16px;  
}  

.back-btn {  
  background-color: #f0f0f0; /* Light gray for back button */  
  color: #555;  
}  

.back-btn:hover {  
  background-color: #e0e0e0; /* Darker gray on hover */  
}  

.register-btn {  
  background-color: #67c23a; /* Element UI success color */  
  color: white;  
}  

.register-btn:hover {  
  background-color: #85d7a6; /* Hover effect */  
}  
</style> 
