import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import ArticlesList from './components/ArticlesList.vue'
import AddArticles from './components/AddArticles.vue'
import Details from './components/Details.vue'
import Edit from './components/Edit.vue'


Vue.use(Router)

const router = new Router({
  mode: 'hash',
    routes: [
      {  
        path: '*',  
        redirect: '/login' // 或者指向一个 404 组件  
      },
      {
        path: '/',
        redirect: '/login'
      },
      {
        path: '/login',
        component: Login
      },
      {
        path: '/register',
        component: Register,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/articlesList',
        component: ArticlesList,
      },
      {
        path: '/add',
        component: AddArticles,
      },
      {
        path: '/details/:id',
        component: Details,
        props: true
      },
      {
        path: '/edit/:id',
        component: Edit,
        props: true
      },
    ]
  })

  router.beforeEach((to,from,next) => {
  
    if (to.path === "/login" || to.path === "/register") return next();
    const tokenStr = window.sessionStorage.getItem("token")
    if (!tokenStr) return next("/login")
    next();
  })
  

  export default router