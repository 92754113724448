<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="edit">
    <h1>Articles</h1>
    <form @submit.prevent="updateArticle">
      <div>
        <label for="title">标题：</label>
        <textarea type="text" id="title" v-model="article.title"></textarea>
      </div>
      <div>
        <label for="content">内容：</label>
        <textarea id="content" v-model="article.content"></textarea>
      </div>
      <button type="submit" class="update_articles">更新</button>
    </form>
    <router-link to="/articlesList" class="my_articles">返回文章列表</router-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    id: {
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      article: {
        title: "",
        content: "",
      },
    };
  },
  methods: {
    updateArticle() {
      if (!this.article.content || !this.article.title) {
        return alert("标题或内容不能为空");
      }
        axios
          .put(`http://47.120.26.136:3005/posts/${this.id}`, {
            title: this.article.title,
            content: this.article.content,
          })
          .then(() => {
            console.log("更新成功");
            this.$router.push("/articlesList");
          })
          .catch((error) => {
            console.log(error);
          });
          this.$message({
            message:"更新成功",
            type:"success"
          });
    },
  },
  created() {
    axios
      .get(`http://47.120.26.136:3005/posts/${this.id}`)
      .then((res) => {
        this.article = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.edit {
  width: 60%;
  margin: 50px auto;
}
.edit h1 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 60px;
}
.edit label {
  font-size: 24px;
  display: block;
  line-height: 40px;
}
.edit input {
  height: 40px;
  width: 100%;
  border: 1px solid #000;
  padding: 10px;
  font-size: 20px;
}
#title {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 50px;
}
#content {
  width: 100%;
  min-height: 600px;
  padding: 20px;
  font-size: 20px;

}
.update_articles {
  width: 80px;
  height: 40px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}
.my_articles {
  padding: 0 15px;
  line-height: 40px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  position: fixed;
  right: 20%;
  top: 15px;
  background-color: rgb(42, 94, 76);
  text-align: center;
  color: #fff;
}
</style>
