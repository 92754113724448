
<template>
  <div class="add-articles">
    <form>
      <div class="add-tit">
        <label>标题：</label>
        <input type="text" v-model="article.title" />
      </div>
      <div class="add-content">
        <label>内容：</label>
        <textarea v-model="article.body"></textarea>
      </div>
      <button type="button" @click="addArticle" class="publish-btn">发布</button>
    </form>
    <router-link to="/articlesList" class="my_articles">文章列表</router-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      article: {
        title: "",
        body: ""
      }
    };
  },
  methods: {
    addArticle() {
      // 在这里发送新增文章的请求
      if (!this.article.body || !this.article.title) {
        return this.$message({
            message: "标题或内容不能为空",
            type: "warning"
          });
      }
      axios
        .post("http://47.120.26.136:3005/posts", {
          title: this.article.title,
          content: this.article.body
        })
        .then(res => {
          console.log(res.data);
          this.$router.push("/articlesList");
          this.$notify({
          title: '添加成功',
          message: '',
          type: 'success'
        });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.add-articles {
  width: 600px;
  margin: 50px auto;
}
.add-articles label {
  font-size: 24px;
  line-height: 50px;
}
.add-tit input {
  width: 100%;
  height: 50px;
  border: 1px solid #333;
  padding: 10px;
}
textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
}
.publish-btn {
  width: 100px;
  height: 46px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  font-size: 20px;
  background-color: #4e6ef2;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
}
.my_articles {
  width: 80px;
  line-height: 40px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  position: fixed;
  right: 30%;
  top: 20px;
  background-color: rgb(42, 94, 76);
  text-align: center;
  color: #fff;
}
</style>
