<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="art-details">
    <h2>{{ article.title }}</h2>
    <p>{{ article.content }}</p>
    <router-link to="/articlesList" class="my_articles">返回文章列表</router-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    id: {
      default: 0,
      required: true,
    },
  },

  data() {
    return {
      article: {
        title: "",
        content: "",
      },
    };
  },
  created() {
    this.getArticle();
  },
  methods: {
    getArticle() {
      // 向后台请求文章信息
      axios
        .get(`http://47.120.26.136:3005/posts/${this.id}`)
        .then((response) => {
          this.article = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.my_articles{
  padding: 0 15px;
  line-height: 40px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  position: fixed;
  right: 10%;
  top: 15px;
  background-color: rgb(42, 94, 76);
  text-align: center;
  color: #fff;
}
.art-details{
    max-width: 60%;
    margin: 0 auto;
}
.art-details h2{
    font-size: 40px;
    color: rgb(35, 90, 71);
    text-align: center;
    margin: 30px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.art-details p{
    text-align: justify;
    text-indent: 2em;
    color: #333;
    font-size: 18px;
    line-height: 40px;
}
</style>
