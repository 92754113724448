<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="login">
    <h2 class="login-tit">Login</h2>
    <form @submit.prevent="login">
      <div>
        <label>用户名:</label>
        <el-input class="ipt" type="text" v-model="username" required />
      </div>
      <div>
        <label>密&emsp;码:</label>
        <el-input class="ipt" type="password" v-model="password" required />
      </div>
    </form>
    <div class="btn">
      <button class="register-btn" @click="go_register">注册</button>
      <button type="submit" class="login-btn" @click="login">登录</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "admin",
      password: ""
    };
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        return this.$message("·请填写用户名或密码");
      }
      axios
        .post("http://47.120.26.136:3005/login", {
          username: this.username,
          password: this.password
        })
        .then(res => {
          // 删除token
          sessionStorage.removeItem("token");
          if (!res.data.token) return this.$message.error("用户名或密码错误");
          this.$message({
            message: "登陆成功",
            type: "success"
          });
          // 存储token
          sessionStorage.setItem("token", res.data.token);
          this.$router.replace("/articlesList");
        })
        .catch(err => {
          console.log(err);
          this.$message({
            message: "用户名或密码错误",
            type: "warning"
          });
        });
    },
    go_register() {
      this.$router.replace("/register");
    }
  }
};
</script>
<style scoped>
.login {
  width: 400px;
  margin: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-tit {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-item {
  margin-bottom: 15px;
}
.login label {
  margin: 10px 0;
  display: block;
}
.form-item label {
  font-size: 14px;
  color: #555;
}

.ipt {
  width: 100%;
  margin-top: 5px;
}

.btn-group {
  display: flex;
  justify-content: space-between;
}

.register-btn,
.login-btn {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}
.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.register-btn {
  background-color: #409eff; /* Element UI primary color */
  color: white;
  display: none;
}

.register-btn:hover {
  background-color: #66b1ff; /* Hover effect */
}

.login-btn {
  background-color: #67c23a; /* Element UI success color */
  color: white;
  margin: 0 auto;
  margin-top: 20px;
}

.login-btn:hover {
  background-color: #85d7a6; /* Hover effect */
}
</style>
