<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
}
</script>

<style>

* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

html,
body {
    color: #333;
    font-size: 16px;
    font-family: "微软雅黑";
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: left;
    max-width: 1920px;
}

h1,
h2,
h3,
h4,
h5,
h6,
i,
em {
    font-style: normal;
    font-weight: normal;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    display: block;
}

img,
input {
    border: 0;
    outline: none;
}
</style>
