<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tit-list">
    <div class="exit" @click="exit">退出</div>
    <h2 class="tit">Articles</h2>
    <div class="search">
      <el-input type="text" v-model="keyword" placeholder="请输入检索词..." />
    </div>
    <ul>
      <li v-for="article in filteredArticles" :key="article.id">
        <h3 class="articles-tit">{{ article.title}}</h3>
        <p>{{ article.content }}</p>
        <div class="art-btn">
          <el-button type="primary" class="details_btn" @click="look_details(article.id)">查看详情</el-button>
          <el-button type="primary" class="edit_btn" @click="edit_article(article.id)">查看文章</el-button>
          <el-button type="danger" class="delete_btn" @click="delete_article(article.id)">删除文章</el-button>
        </div>
      </li>
    </ul>
    <router-link to="/add" class="add-btn">添加文章</router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      articles: [],
      keyword: ""
    };
  },
  mounted() {
    axios
      .get("http://47.120.26.136:3005/posts")
      .then(res => {
        this.articles = res.data.reverse();
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {

    exit() {
      this.$confirm('您将退出此页面, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sessionStorage.removeItem("token");
          this.$router.push("/login");
          this.$message({
            type: 'warning',
            message: '已退出!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });          
        });
    },
    delete_article(id) {
      // 发送删除文章的请求
      event.stopPropagation();

      
        this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.delete(`http://47.120.26.136:3005/posts/${id}`).then(() => {
          // 请求成功后，删除本地的文章数据
          this.articles = this.articles.filter(article => article.id !== id);
        });
          this.$notify({
            title: '已删除该文章',
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
    },
    look_details(id) {
      this.$router.push("/details/" + id);
    },
    edit_article(id) {
      this.$router.push("/edit/" + id);
    }
  },
  computed: {
    filteredArticles() {
      if (this.keyword) {
        return this.articles.filter(article => {
          return article.title.includes(this.keyword);
        });
      } else {
        return this.articles;
      }
    }
  }
};
</script>
<style scoped>
.details_btn{
  display: none;
}
.search .el-input {
  width: 100%;
  height: 40px;
  margin: 20px 0;
  padding: 10px;
}
.tit-list {
  width: 60%;
  margin: 50px auto;
}
.tit {
  font-size: 32px;
  text-align: center;
}
.tit-list ul li .articles-tit {
  font-size: 24px;
  color: cadetblue;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 100px;
}
.tit-list ul li p {
  text-align: justify;
  color: #333;
  font-size: 18px;
  line-height: 30px;
  height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.tit-list ul li {
  padding: 30px;
  margin: 20px 0;
  position: relative;
  transition: all 0.3s;
}
.tit-list ul li:hover{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
.add-btn {
  width: 80px;
  background-color: rgb(53, 127, 134);
  text-align: center;
  line-height: 40px;
  color: #fff;
  position: fixed;
  right: 20%;
  top: 20px;
}
.exit {
  width: 80px;
  line-height: 40px;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 24px;
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.art-btn {
  text-align: right;
}
.art-btn > button {
  margin: 20px 10px;
}


</style>